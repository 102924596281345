<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('list.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t('list.entries') }}</label>
          </b-col>
          <b-col cols="3">
            <b-row>
              <b-col cols="2">
                <span class="text-nowrap">{{
                  $t('arl_codes.filter.from')
                }}</span>
              </b-col>
              <b-col cols="10">
                <date-picker
                  :element-required="false"
                  element-field="filter_date_from"
                  :root-element-data="filter"
                  :element-clearable="true" />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="3">
            <b-row>
              <b-col cols="2">
                <span class="text-nowrap">{{ $t('arl_codes.filter.to') }}</span>
              </b-col>
              <b-col cols="10">
                <date-picker
                  :element-required="false"
                  element-field="filter_date_to"
                  :root-element-data="filter"
                  :element-clearable="true" />
              </b-col>
            </b-row>
          </b-col>
          <b-col
            cols="4"
            class="d-flex justify-content-start mb-1 mb-md-0">
            <b-form-input
              v-model="query"
              class="d-inline-block mr-1"
              :placeholder="$t('table.search.search')" />
            <b-button
              variant="primary"
              class="mb-1 mr-sm-1"
              @click="export2excel">
              <span class="text-nowrap">{{ $t('list.export') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <vue-double-scrollbar v-if="tableDataLoaded">
        <b-table
          ref="refElementListTable"
          class="position-relative"
          :items="items"
          :fields="tableColumns"
          primary-key="identity"
          show-empty
          :tbody-tr-class="rowClass"
          empty-text="No matching records found">
          <!-- Column: names -->
          <template #cell(name)="data">
            {{ data.item.name }}
          </template>
          <template #cell(date)="data">
            {{ formatDate(data.item, 'date') }}
          </template>
          <template #cell(authorization_date)="data">
            {{ formatDate(data.item, 'authorization_date') }}
          </template>
          <template #cell(vigence_date)="data">
            {{ formatDate(data.item, 'vigence_date') }}
          </template>
          <!-- Column: actions -->
          <template #cell(actions)="data">
            <b-col class="flex flex-row flex-wrap">
              <b-button
                :id="`view-button-${data.item.id}`"
                style="margin: 1px"
                variant="primary"
                :to="{
                  name: 'freezer-pending-view',
                  params: { id: data.item.freezer_id },
                }">
                <feather-icon icon="FileTextIcon" />
              </b-button>
              <b-button
                :id="`edit-button-${data.item.id}`"
                style="margin: 1px"
                variant="primary"
                :to="{
                  name: 'freezer-pending-edit',
                  params: { id: data.item.freezer_id },
                }">
                <feather-icon icon="EditIcon" />
              </b-button>

              <!-- ? VIEW -->
              <b-tooltip
                :target="`view-button-${data.item.id}`"
                :title="$t('actions.details')"
                triggers="hover"
                :noninteractive="true"
                fallback-placement="counterclockwise">
              </b-tooltip>
              <!-- ? EDIT -->
              <b-tooltip
                :target="`edit-button-${data.item.id}`"
                :title="$t('actions.edit')"
                triggers="hover"
                :noninteractive="true"
                fallback-placement="counterclockwise">
              </b-tooltip>
            </b-col>
          </template>
        </b-table>
      </vue-double-scrollbar>
      <!-- Pagination -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="6"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-if="tableDataLoaded"
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="total"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18" />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <div />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import DatePicker from '@/views/core/DatePicker.vue'
import * as XLSX from 'xlsx'
import moment from 'moment'
import storeModule from '../storeModule'
import Filters from './filters.vue'
import SessionStorage from '@/handlers/SessionStorage'

export default {
  components: {
    DatePicker,
    Filters,
    BTooltip,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      items: [],
      total: 1,
      perPage: 5,
      currentPage: this.currentPage,
      currentPath: window.location.pathname,
      perPageOptions: [5, 10, 25, 50, 100],
      query: '',
      filter: {},
      tableColumns: [
        {
          key: 'id',
          label: this.$t('arl_codes.object.number'),
          sortable: true,
        },
        {
          key: 'date',
          label: this.$t('arl_codes.object.date'),
          sortable: true,
        },
        {
          key: 'case_number',
          label: this.$t('arl_codes.object.case_number'),
          sortable: true,
        },
        {
          key: 'arl_name',
          label: this.$t('arl_codes.object.arl'),
          sortable: true,
        },
        {
          key: 'patient_identity',
          label: this.$t('arl_codes.object.patient_identity'),
          sortable: true,
        },
        {
          key: 'patient_name',
          label: this.$t('arl_codes.object.patient_name'),
          sortable: true,
        },
        {
          key: 'service_type_value',
          label: this.$t('arl_codes.object.service_type'),
          sortable: true,
        },
        {
          key: 'follow_type_name',
          label: this.$t('arl_codes.object.follow_type'),
          sortable: true,
        },
        {
          key: 'medical_order_status_value',
          label: this.$t('arl_codes.object.medical_order_status'),
          sortable: true,
        },
        {
          key: 'actions',
          label: this.$t('actions.tittle'),
          thStyle: 'width: 300px;',
        },
      ],
      tableExcelColumns: [
        {
          key: 'id',
          label: this.$t('arl_codes.object.number'),
          sortable: true,
        },
        {
          key: 'date',
          label: this.$t('arl_codes.object.date'),
          sortable: true,
        },
        {
          key: 'internal_code',
          label: this.$t('arl_codes.object.internal_code'),
          sortable: true,
        },
        {
          key: 'case_number',
          label: this.$t('arl_codes.object.case_number'),
          sortable: true,
        },
        {
          key: 'arl_name',
          label: this.$t('arl_codes.object.arl'),
          sortable: true,
        },
        {
          key: 'patient_identity',
          label: this.$t('arl_codes.object.patient_identity'),
          sortable: true,
        },
        {
          key: 'patient_name',
          label: this.$t('arl_codes.object.patient_name'),
          sortable: true,
        },
        {
          key: 'bundle_name',
          label: this.$t('arl_codes.object.bundle_name'),
          sortable: true,
        },
        {
          key: 'service_group_name',
          label: this.$t('arl_codes.object.service_group_name'),
          sortable: true,
        },
        {
          key: 'service_name',
          label: this.$t('arl_codes.object.service_code'),
          sortable: true,
        },
        {
          key: 'service_type_value',
          label: this.$t('arl_codes.object.service_type'),
          sortable: true,
        },
        {
          key: 'follow_type_value',
          label: this.$t('arl_codes.object.next_consult'),
          sortable: true,
        },
        {
          key: 'medical_order_status_value',
          label: this.$t('arl_codes.object.medical_order_status'),
          sortable: true,
        },
        {
          key: 'authorization_date',
          label: this.$t('arl_codes.object.authorization_date'),
          sortable: true,
        },
        {
          key: 'arl_code',
          label: this.$t('arl_codes.object.arl_code'),
          sortable: true,
        },
        {
          key: 'authorizated_by',
          label: this.$t('arl_codes.object.authorizated_by'),
          sortable: true,
        },
        {
          key: 'observations',
          label: this.$t('arl_codes.object.observations'),
          sortable: true,
        },
        {
          key: 'actions',
          label: this.$t('actions.tittle'),
          thStyle: 'width: 300px;',
        },
      ],
      currentSelection: {},
      sheets: [{ name: 'SheetOne', data: [{ c: 2 }] }],
      ARL_CODES_APP_STORE_MODULE_NAME: 'app-freezer-pending',
      RESOURCES_ELEMENT_NAME: 'arl_codes',
      tableDataLoaded: false,
    }
  },
  watch: {
    perPage(val) {
      this.fetchElements()
    },
    currentPage(val) {
      this.fetchElements()
    },
    query(val) {
      this.fetchElements()
    },
    'filter.filter_date_from': function (val) {
      this.fetchElements()
    },
    'filter.filter_date_to': function (val) {
      this.fetchElements()
    },
  },
  async mounted() {
    this.filter = { filter_date_from: undefined, filter_date_to: undefined }
    if (!store.hasModule(this.ARL_CODES_APP_STORE_MODULE_NAME))
      store.registerModule(this.ARL_CODES_APP_STORE_MODULE_NAME, storeModule)

    this.search_storage = new SessionStorage('avenir')
    var data = JSON.parse(sessionStorage.getItem(this.currentPath))

    if (data != null) {
      this.query = data.query
      this.filter.filter_date_from = data.date_from
      this.filter.filter_date_to = data.date_to
    }
    await this.loadSearch()
    this.fetchElements()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ARL_CODES_APP_STORE_MODULE_NAME))
      store.unregisterModule(this.ARL_CODES_APP_STORE_MODULE_NAME)
  },
  methods: {
    rowClass(item, index) {
      if (!item) {
        console.log('Item is undefined');
        return '';
      }
      console.log('Evaluating row:', item);
      if (item.priority_case) {
        console.log('Este si es')
        return 'priority-case-row';
      }
      return '';
    },
    formatDate(element, field) {
      return element[field]
        ? moment(element[field], 'DD/MM/YYYY').format('DD/MM/YYYY')
        : ''
    },
    filterJson(jsonData) {
      const filterList = this.tableExcelColumns.map((a) => a.key)
      filterList.pop()
      return jsonData.map((v) => filterList.map((j) => v[j]))
    },
    getHeader() {
      const res = {}
      for (let i = 0; i < this.tableExcelColumns.length; i++) {
        res[this.tableExcelColumns[i].key] = this.tableExcelColumns[i].label
      }
      return res
    },
    format(jsonData) {},
    async loadSearch() {
      const search = this.search_storage.getValueStorage()
      this.perPage = search.perPage || 5
      this.currentPage = search.page || 1
      this.query = search.query || null
      this.filter.filter_date_from = search.from || null
      this.filter.filter_date_to = search.to || null
    },
    async export2excel() {
      const filename = this.$t('freezer.pending_excel_name')
      const result = await store.dispatch(
        `${this.ARL_CODES_APP_STORE_MODULE_NAME}/fetch_excel_elements`,
        {
          queryParams: {
            perPage: this.perPage,
            page: this.currentPage,
            query: this.query,
            from:
              this.filter.filter_date_from == 'Invalid date' ||
              this.filter.filter_date_from == 'Fecha inválida'
                ? undefined
                : this.filter.filter_date_from,
            to:
              this.filter.filter_date_to == 'Invalid date' ||
              this.filter.filter_date_to == 'Fecha inválida'
                ? undefined
                : this.filter.filter_date_to,
          },
        }
      )
      this.format(result.data.items)
      result.data.items.unshift(this.getHeader())
      const list = this.filterJson(result.data.items)
      const data = XLSX.utils.json_to_sheet(list, { skipHeader: true })
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, data, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },
    beforeGenerate() {
      console.info('test')
    },
    fetchElements() {
      sessionStorage.setItem(
        this.currentPath,
        JSON.stringify({
          query: this.query,
          date_from: this.filter.filter_date_from,
          date_to: this.filter.filter_date_to,
        })
      )

      store
        .dispatch(`${this.ARL_CODES_APP_STORE_MODULE_NAME}/fetch_elements`, {
          queryParams: {
            perPage: this.perPage,
            page: this.currentPage,
            query: this.query,
            from:
              this.filter.filter_date_from == 'Invalid date' ||
              this.filter.filter_date_from == 'Fecha inválida'
                ? undefined
                : this.filter.filter_date_from,
            to:
              this.filter.filter_date_to == 'Invalid date' ||
              this.filter.filter_date_to == 'Fecha inválida'
                ? undefined
                : this.filter.filter_date_to,
          },
        })
        .then((response) => {
          this.items = response.data.items
          this.total = response.data.total
          this.currentPage = response.data.page
          this.tableDataLoaded = true
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
<style lang="scss">
.priority-case-row {
  background-color: #d7c2ed !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
